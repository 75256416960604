*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
  font-size: 62.5%; // 1rem = 10px; 10px/16px = 62.5%
  
  @include respond('tab-port') {
    font-size: 50%;
  }
  
  @include respond('tab-port') {
    font-size: 40%;
  }
}

body {
  font-size: $default-font-size;
  font-family: 'Rubik', sans-serif;
  font-weight: 400;
  line-height: 1.5;
  color: $color-text;
}

.container {
  width: 100%;
  max-width: 124rem;
}