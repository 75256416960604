.navbar {
  padding: 4rem 3rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.main-logo {
  width: 8.5rem;
}

.social-icon {
  cursor: pointer;
  width: 2.4rem;
  height: 2.4rem;
  margin-left: 3rem;
  fill: $color-title;
  transition: fill .8s ease;
  
  &--instagram {
    &:hover  {
      fill: rgb(189, 6, 76);
    }
  }
  &--github {
    &:hover  {
      fill: rgb(32, 32, 32);
    }
  }
  &--linkedin {
    &:hover  {
      fill: rgb(79, 79, 255);
    }
  }
}
