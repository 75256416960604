.footer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  
  &--bottom {
    padding: 3rem;
    display: flex;
    justify-content: center;
  }
}