.header {
  padding: 3rem;
}

.main-title {
  font-size: 6.4rem;
  color: black;
  display: flex;
  flex-direction: column;
  line-height: 7.5rem;
  b { font-weight: 500;}
}

.vulcan-icon {
  height: 6rem;
  margin-top: -10px;
}

.main-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8rem 0;
  color: $color-title;
  p {
    width: 45%;
    color: $color-text;
  }
  
  @include respond('tab-port') {
    flex-direction: column;
    justify-content: center;
  }
}

.main-image {
  width: 45%;
  height: 45rem;
  background-image: url('../../assets/main.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 8px;
  
  @include respond('tab-port') {
    width: 100%;
    height: 60rem;
  }
}

.the-lab {
  padding: 3rem;
  
  h2 {
    color: $color-title;
    font-size: 3.4rem;  
  }
}