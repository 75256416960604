// colors
$color-primary: #0058A3;
$color-primary-light: #7CA7CB;
$color-primary-dark: #262B3F;
$color-secondary: #FFCB05;
$color-secondary-light: #FFE582;
$color-default: #F8F8F8;
$color-title: #262626;
$color-text: #737373;

// font
$default-font-size: 1.6rem;
