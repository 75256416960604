.contact {
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: #313131;
  padding: 5rem 3rem;

  &--container {
    display: flex;
    justify-content: space-between;
    padding: 0 3rem;
    
    @include respond('tab-port') {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
}

.contact-info {
  width: 35%;
  color: #fff;
  
  p {
    padding: 2rem 0;
  }
  
  @include respond('tab-port') { width: 100%; }
}

.contact-link {
  cursor: pointer;
  display: flex;
  align-items: center;
  color: #fff;
  text-decoration: underline;
  font-size: 1.8rem;
}

.contact-image {
  width: 30%;
  height: 25rem;
  background-image: url('../../assets/contact.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 8px;
  
  @include respond('tab-port') { 
    width: 80%; 
    height: 30rem;
  }
}